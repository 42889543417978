var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showLoader}},[_c('b-card',{attrs:{"title":_vm.$t('general.invoices')}},[_c('validation-observer',{ref:"validation"},[_c('b-form-group',{attrs:{"label":_vm.$t('config.configuration.days_until_deadline')}},[_c('validation-provider',{attrs:{"name":_vm.$t('config.configuration.days_until_deadline'),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.showInvoiceConfig.number_of_days),callback:function ($$v) {_vm.$set(_vm.showInvoiceConfig, "number_of_days", $$v)},expression:"showInvoiceConfig.number_of_days"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('b-form-group',{attrs:{"label":_vm.$t('form_data.post_code')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.post_code'),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.showInvoiceConfig.postcode),callback:function ($$v) {_vm.$set(_vm.showInvoiceConfig, "postcode", $$v)},expression:"showInvoiceConfig.postcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-8"},[_c('b-form-group',{attrs:{"label":_vm.$t('form_data.city')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.city'),"rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.showInvoiceConfig.city),callback:function ($$v) {_vm.$set(_vm.showInvoiceConfig, "city", $$v)},expression:"showInvoiceConfig.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-form-group',{attrs:{"label":_vm.$t('config.configuration.trr')}},[_c('validation-provider',{attrs:{"name":_vm.$t('config.configuration.trr'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.showInvoiceConfig.trr),callback:function ($$v) {_vm.$set(_vm.showInvoiceConfig, "trr", $$v)},expression:"showInvoiceConfig.trr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('config.configuration.size_of_table_text')}},[_c('validation-provider',{attrs:{"name":_vm.$t('config.configuration.size_of_table_text'),"rules":"required|min_value:6|max_value:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.showInvoiceConfig.size_of_text),callback:function ($$v) {_vm.$set(_vm.showInvoiceConfig, "size_of_text", $$v)},expression:"showInvoiceConfig.size_of_text"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-checkbox',{staticClass:"mb-1",attrs:{"switch":""},model:{value:(_vm.showInvoiceConfig.display_invoice_table),callback:function ($$v) {_vm.$set(_vm.showInvoiceConfig, "display_invoice_table", $$v)},expression:"showInvoiceConfig.display_invoice_table"}},[_vm._v(" "+_vm._s(_vm.$t('config.configuration.display_invoice_table'))+" ")]),_c('b-form-checkbox',{staticClass:"mb-1",attrs:{"switch":""},model:{value:(_vm.showInvoiceConfig.display_invoice_author),callback:function ($$v) {_vm.$set(_vm.showInvoiceConfig, "display_invoice_author", $$v)},expression:"showInvoiceConfig.display_invoice_author"}},[_vm._v(" "+_vm._s(_vm.$t('config.configuration.display_invoice_author'))+" ")]),_c('div',[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.showInvoiceConfig.already_printed_header),callback:function ($$v) {_vm.$set(_vm.showInvoiceConfig, "already_printed_header", $$v)},expression:"showInvoiceConfig.already_printed_header"}},[_vm._v(" "+_vm._s(_vm.$t('config.configuration.already_printed_header'))+" ")]),(_vm.showInvoiceConfig.already_printed_header)?_c('b-form-group',{attrs:{"label":_vm.$t('config.configuration.header_height_mm')}},[_c('validation-provider',{attrs:{"name":_vm.$t('config.configuration.header_height_mm'),"rules":"required|min_value:10|max_value:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.showInvoiceConfig.header_offset),callback:function ($$v) {_vm.$set(_vm.showInvoiceConfig, "header_offset", $$v)},expression:"showInvoiceConfig.header_offset"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3654689780)})],1):_vm._e()],1),_c('div',{staticClass:"mt-1"},[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.showInvoiceConfig.already_printed_footer),callback:function ($$v) {_vm.$set(_vm.showInvoiceConfig, "already_printed_footer", $$v)},expression:"showInvoiceConfig.already_printed_footer"}},[_vm._v(" "+_vm._s(_vm.$t('config.configuration.already_printed_footer'))+" ")]),(!_vm.showInvoiceConfig.already_printed_footer)?_c('b-form-group',{attrs:{"label":_vm.$t('config.configuration.footer')}},[_c('validation-provider',{attrs:{"name":_vm.$t('config.configuration.footer'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{model:{value:(_vm.showInvoiceConfig.footer),callback:function ($$v) {_vm.$set(_vm.showInvoiceConfig, "footer", $$v)},expression:"showInvoiceConfig.footer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2095460800)})],1):_vm._e(),(_vm.showInvoiceConfig.already_printed_footer)?_c('b-form-group',{attrs:{"label":_vm.$t('config.configuration.footer_height_mm')}},[_c('validation-provider',{attrs:{"name":_vm.$t('config.configuration.footer_height_mm'),"rules":"required|min_value:10|max_value:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.showInvoiceConfig.footer_offset),callback:function ($$v) {_vm.$set(_vm.showInvoiceConfig, "footer_offset", $$v)},expression:"showInvoiceConfig.footer_offset"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1106285614)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":_vm.$t('config.configuration.notes')}},[_c('b-form-textarea',{model:{value:(_vm.showInvoiceConfig.notes),callback:function ($$v) {_vm.$set(_vm.showInvoiceConfig, "notes", $$v)},expression:"showInvoiceConfig.notes"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('config.configuration.signature')}},[_c('validation-provider',{attrs:{"name":_vm.$t('config.configuration.signature'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"options":_vm.signature_types,"reduce":function (ele) { return ele.type; }},model:{value:(_vm.showInvoiceConfig.signature_type),callback:function ($$v) {_vm.$set(_vm.showInvoiceConfig, "signature_type", $$v)},expression:"showInvoiceConfig.signature_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-end mt-2"},[(_vm.$hasPermission(_vm.$permissions.ConfigurationWrite))?_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validate}},[_vm._v(" "+_vm._s(_vm.$t('config.configuration.change'))+" ")]):_vm._e()],1)],1),_c('b-card',{attrs:{"title":_vm.$t('config.configuration.own_consumption')}},[_c('validation-observer',{ref:"validationOwnConsumption"},[_c('validation-provider',{attrs:{"name":_vm.$t('config.configuration.type_own_consumption'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mb-2",attrs:{"appendToBody":"","options":_vm.own_consumption_types,"reduce":function (name) { return name.type; }},model:{value:(_vm.own_consumption.own_consumption_type),callback:function ($$v) {_vm.$set(_vm.own_consumption, "own_consumption_type", $$v)},expression:"own_consumption.own_consumption_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.own_consumption && _vm.own_consumption.own_consumption_type === 2)?_c('b-form-group',{staticClass:"mt-3",attrs:{"label":_vm.$t('config.configuration.own_consumption_percentage')}},[_c('validation-provider',{attrs:{"name":_vm.$t('config.configuration.own_consumption_percentage_input'),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.own_consumption.percentage),callback:function ($$v) {_vm.$set(_vm.own_consumption, "percentage", $$v)},expression:"own_consumption.percentage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3683107413)})],1):_vm._e()],1),_c('div',{staticClass:"d-flex justify-content-end"},[(_vm.$hasPermission(_vm.$permissions.ConfigurationWrite))?_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validateOwnConsumption}},[_vm._v(" "+_vm._s(_vm.$t('config.configuration.change'))+" ")]):_vm._e()],1)],1),(_vm.$hasTierPermission(_vm.Tiers.Standard, _vm.Tiers.Premium))?_c('b-card',{attrs:{"title":_vm.$t('config.configuration.warehouse_average_price')}},[_c('validation-observer',{ref:"validationWarehouseStock"},[_c('validation-provider',{attrs:{"name":_vm.$t('config.configuration.type_own_consumption'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mb-2",attrs:{"appendToBody":"","options":_vm.warehouse_stock_types,"reduce":function (name) { return name.type; }},model:{value:(_vm.warehouse_stock.stock_type),callback:function ($$v) {_vm.$set(_vm.warehouse_stock, "stock_type", $$v)},expression:"warehouse_stock.stock_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,320382520)})],1),_c('div',{staticClass:"d-flex justify-content-end"},[(_vm.$hasPermission(_vm.$permissions.ConfigurationWrite))?_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validateWarehouseStock}},[_vm._v(" "+_vm._s(_vm.$t('config.configuration.change'))+" ")]):_vm._e()],1)],1):_vm._e(),_c('b-card',{attrs:{"title":_vm.$t('general.payment_types')}},[_c('validation-observer',{ref:"validationPaymentType"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[(_vm.paymentTypes && _vm.paymentTypes.length > 0)?_c('b-form-group',{attrs:{"label":_vm.$t('config.configuration.cash')}},[_c('validation-provider',{attrs:{"name":_vm.$t('config.configuration.cash'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"appendToBody":"","options":_vm.paymentTypes,"clearable":_vm.$hasPermission(_vm.$permissions.ConfigurationWrite),"disabled":!_vm.$hasPermission(_vm.$permissions.ConfigurationWrite),"reduce":function (item) { return item.id; },"selectable":function (ele) { return ele.id !== _vm.paymentTypeCard; },"getOptionLabel":function (paymentType) { return paymentType.name; }},model:{value:(_vm.paymentTypeCash),callback:function ($$v) {_vm.paymentTypeCash=$$v},expression:"paymentTypeCash"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2779189360)})],1):_vm._e()],1),_c('div',{staticClass:"col-6"},[(_vm.paymentTypes && _vm.paymentTypes.length > 0)?_c('b-form-group',{attrs:{"label":_vm.$t('config.configuration.card')}},[_c('validation-provider',{attrs:{"name":_vm.$t('config.configuration.card'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"appendToBody":"","options":_vm.paymentTypes,"clearable":_vm.$hasPermission(_vm.$permissions.ConfigurationWrite),"disabled":!_vm.$hasPermission(_vm.$permissions.ConfigurationWrite),"reduce":function (item) { return item.id; },"selectable":function (ele) { return ele.id !== _vm.paymentTypeCash; },"getOptionLabel":function (paymentType) { return paymentType.name; }},model:{value:(_vm.paymentTypeCard),callback:function ($$v) {_vm.paymentTypeCard=$$v},expression:"paymentTypeCard"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2655360720)})],1):_vm._e()],1)])]),_c('div',{staticClass:"d-flex justify-content-end"},[(_vm.$hasPermission(_vm.$permissions.ConfigurationWrite))?_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validatePaymentType}},[_vm._v(" "+_vm._s(_vm.$t('config.configuration.change'))+" ")]):_vm._e()],1)],1),_c('b-card',{attrs:{"title":_vm.$t('general.cash_register')}},[_c('validation-observer',{ref:"validationCashRegister"},[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.cashRegisterConfig.print_informative_invoice),callback:function ($$v) {_vm.$set(_vm.cashRegisterConfig, "print_informative_invoice", $$v)},expression:"cashRegisterConfig.print_informative_invoice"}},[_vm._v(_vm._s(_vm.$t('config.configuration.printing_info_bill')))]),(_vm.$hasTierPermission(_vm.Tiers.Premium))?_c('div',[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.cashRegisterConfig.product_categories_to_not_join),callback:function ($$v) {_vm.$set(_vm.cashRegisterConfig, "product_categories_to_not_join", $$v)},expression:"cashRegisterConfig.product_categories_to_not_join"}},[_vm._v(_vm._s(_vm.$t('config.configuration.choose_article_category')))]),(_vm.cashRegisterConfig.product_categories_to_not_join)?_c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('config.configuration.subcategories_lower'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('config.configuration.subcategories')}},[_c('v-select',{attrs:{"options":_vm.productSubcategories,"multiple":"","label":"name","reduce":function (ele) { return ele.id; }},model:{value:(_vm.cashRegisterConfig.selected_product_categories),callback:function ($$v) {_vm.$set(_vm.cashRegisterConfig, "selected_product_categories", $$v)},expression:"cashRegisterConfig.selected_product_categories"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2221030184)})],1):_vm._e()],1):_vm._e()],1),_c('div',{staticClass:"d-flex justify-content-end mt-2"},[(_vm.$hasPermission(_vm.$permissions.ConfigurationWrite))?_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validateCashRegisterConfiguration}},[_vm._v(" "+_vm._s(_vm.$t('config.configuration.change'))+" ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }