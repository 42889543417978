<template>
    <b-overlay :show="showLoader">
        <b-card :title="$t('general.invoices')">
            <validation-observer ref="validation">
                <b-form-group :label="$t('config.configuration.days_until_deadline')">
                    <validation-provider
                        #default="{ errors }"
                        :name="$t('config.configuration.days_until_deadline')"
                        rules="required|numeric"
                    >
                        <b-form-input v-model="showInvoiceConfig.number_of_days" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <div class="row">
                    <div class="col-4">
                        <b-form-group :label="$t('form_data.post_code')">
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('form_data.post_code')"
                                rules="required|numeric"
                            >
                                <b-form-input v-model="showInvoiceConfig.postcode" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                    <div class="col-8">
                        <b-form-group :label="$t('form_data.city')">
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('form_data.city')"
                                rules="required|alpha_spaces"
                            >
                                <b-form-input v-model="showInvoiceConfig.city" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                </div>
                <b-form-group :label="$t('config.configuration.trr')">
                    <validation-provider
                        #default="{ errors }"
                        :name="$t('config.configuration.trr')"
                        rules="required"
                    >
                        <b-form-input v-model="showInvoiceConfig.trr" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group :label="$t('config.configuration.size_of_table_text')">
                    <validation-provider
                        #default="{ errors }"
                        :name="$t('config.configuration.size_of_table_text')"
                        rules="required|min_value:6|max_value:13"
                    >
                        <b-form-input v-model="showInvoiceConfig.size_of_text" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-checkbox class="mb-1" switch  v-model="showInvoiceConfig.display_invoice_table" >
                    {{ $t('config.configuration.display_invoice_table') }}
                </b-form-checkbox>

                <b-form-checkbox class="mb-1" switch  v-model="showInvoiceConfig.display_invoice_author" >
                    {{ $t('config.configuration.display_invoice_author') }}
                </b-form-checkbox>

                <div>
                    <b-form-checkbox switch  v-model="showInvoiceConfig.already_printed_header">
                        {{ $t('config.configuration.already_printed_header') }}
                    </b-form-checkbox>

                    <b-form-group :label="$t('config.configuration.header_height_mm')" v-if="showInvoiceConfig.already_printed_header">
                        <validation-provider #default="{ errors }" :name="$t('config.configuration.header_height_mm')" rules="required|min_value:10|max_value:50">
                            <b-form-input v-model="showInvoiceConfig.header_offset"></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                </div>

                <div class="mt-1">
                    <b-form-checkbox switch  v-model="showInvoiceConfig.already_printed_footer">
                        {{ $t('config.configuration.already_printed_footer') }}
                    </b-form-checkbox>

                    <b-form-group :label="$t('config.configuration.footer')" v-if="!showInvoiceConfig.already_printed_footer">
                        <validation-provider #default="{ errors }" :name="$t('config.configuration.footer')" rules="required">
                            <b-form-textarea v-model="showInvoiceConfig.footer"></b-form-textarea>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group :label="$t('config.configuration.footer_height_mm')" v-if="showInvoiceConfig.already_printed_footer">
                        <validation-provider #default="{ errors }" :name="$t('config.configuration.footer_height_mm')" rules="required|min_value:10|max_value:50">
                            <b-form-input v-model="showInvoiceConfig.footer_offset"></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group :label="$t('config.configuration.notes')">
                        <b-form-textarea v-model="showInvoiceConfig.notes"></b-form-textarea>
                    </b-form-group>

                    <b-form-group :label="$t('config.configuration.signature')" >
                        <validation-provider #default="{ errors }" :name="$t('config.configuration.signature')" rules="required">
                            <v-select :clearable="false" :options="signature_types" v-model="showInvoiceConfig.signature_type" :reduce="ele => ele.type" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                </div>


            </validation-observer>
            <div class="d-flex justify-content-end mt-2">
                <b-button v-if="$hasPermission($permissions.ConfigurationWrite)" variant="primary" @click="validate"> {{ $t('config.configuration.change') }} </b-button>
            </div>
        </b-card>

        <b-card :title="$t('config.configuration.own_consumption')" >
            <validation-observer ref="validationOwnConsumption">
                <validation-provider
                    #default="{ errors }"
                    :name="$t('config.configuration.type_own_consumption')"
                    rules="required"
                >
                    <v-select
                        class="mb-2"
                        v-model="own_consumption.own_consumption_type"
                        appendToBody
                        :options="own_consumption_types"
                        :reduce="(name) => name.type"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

                <b-form-group
                    class="mt-3"
                    :label="$t('config.configuration.own_consumption_percentage')"
                    v-if="own_consumption && own_consumption.own_consumption_type === 2"
                >
                    <validation-provider
                        #default="{ errors }"
                        :name="$t('config.configuration.own_consumption_percentage_input')"
                        rules="required|numeric"
                    >
                        <b-form-input v-model="own_consumption.percentage" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </validation-observer>
            <div class="d-flex justify-content-end">
                <b-button v-if="$hasPermission($permissions.ConfigurationWrite)" variant="primary" @click="validateOwnConsumption">
                    {{ $t('config.configuration.change') }}
                </b-button>
            </div>
        </b-card>

        <b-card :title="$t('config.configuration.warehouse_average_price')" v-if="$hasTierPermission(Tiers.Standard, Tiers.Premium)">
            <validation-observer ref="validationWarehouseStock">
                <validation-provider
                    #default="{ errors }"
                    :name="$t('config.configuration.type_own_consumption')"
                    rules="required"
                >
                    <v-select
                        class="mb-2"
                        v-model="warehouse_stock.stock_type"
                        appendToBody
                        :options="warehouse_stock_types"
                        :reduce="(name) => name.type"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </validation-observer>
            <div class="d-flex justify-content-end">
                <b-button v-if="$hasPermission($permissions.ConfigurationWrite)" variant="primary" @click="validateWarehouseStock">
                    {{ $t('config.configuration.change') }}
                </b-button>
            </div>
        </b-card>

        <b-card :title="$t('general.payment_types')">
            <validation-observer ref="validationPaymentType">
                <div class="row">
                    <div class="col-6">
                        <b-form-group
                            v-if="paymentTypes && paymentTypes.length > 0"
                            :label="$t('config.configuration.cash')"
                        >
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('config.configuration.cash')"
                                rules="required"
                            >
                                <v-select
                                    appendToBody
                                    v-model="paymentTypeCash"
                                    :options="paymentTypes"
                                    :clearable="$hasPermission($permissions.ConfigurationWrite)"
                                    :disabled="!$hasPermission($permissions.ConfigurationWrite)"
                                    :reduce="(item) => item.id"
                                    :selectable="(ele) => ele.id !== paymentTypeCard"
                                    :getOptionLabel="(paymentType) => paymentType.name"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                    <div class="col-6">
                        <b-form-group
                            v-if="paymentTypes && paymentTypes.length > 0"
                            :label="$t('config.configuration.card')"
                        >
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('config.configuration.card')"
                                rules="required"
                            >
                                <v-select
                                    appendToBody
                                    v-model="paymentTypeCard"
                                    :options="paymentTypes"
                                    :clearable="$hasPermission($permissions.ConfigurationWrite)"
                                    :disabled="!$hasPermission($permissions.ConfigurationWrite)"
                                    :reduce="(item) => item.id"
                                    :selectable="(ele) => ele.id !== paymentTypeCash"
                                    :getOptionLabel="(paymentType) => paymentType.name"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                </div>
            </validation-observer>
            <div class="d-flex justify-content-end">
                <b-button v-if="$hasPermission($permissions.ConfigurationWrite)" variant="primary" @click="validatePaymentType">
                    {{ $t('config.configuration.change') }}
                </b-button>
            </div>
        </b-card>

        <b-card :title="$t('general.cash_register')">
            <validation-observer ref="validationCashRegister">
                <b-form-checkbox switch v-model="cashRegisterConfig.print_informative_invoice">{{$t('config.configuration.printing_info_bill')}}</b-form-checkbox>
                <div v-if="$hasTierPermission(Tiers.Premium)">
                    <b-form-checkbox switch v-model="cashRegisterConfig.product_categories_to_not_join">{{$t('config.configuration.choose_article_category')}}</b-form-checkbox>
                    <div v-if="cashRegisterConfig.product_categories_to_not_join">
                        <validation-provider
                            #default="{ errors }"
                            :name="$t('config.configuration.subcategories_lower')"
                            rules="required"
                        >
                            <b-form-group :label="$t('config.configuration.subcategories')">
                                <v-select  :options="productSubcategories" multiple label="name" :reduce="ele => ele.id" v-model="cashRegisterConfig.selected_product_categories">

                                </v-select>
                            </b-form-group>

                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </div>
                </div>
            </validation-observer>

            <div class="d-flex justify-content-end mt-2">
                <b-button v-if="$hasPermission($permissions.ConfigurationWrite)" variant="primary" @click="validateCashRegisterConfiguration">
                    {{ $t('config.configuration.change') }}
                </b-button>
            </div>
        </b-card>
    </b-overlay>
</template>

<script>
    import { BOverlay, BCard, BFormInput, BButton, BFormGroup, BFormCheckbox, BFormTextarea} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, numeric, alpha, minValue } from '@validations'
    import vSelect from 'vue-select'
    import * as Sentry from '@sentry/vue'
    import itemChange from '@core/mixins/installation-wizard/itemChange'
    import {Tiers} from '@/libs/enums/Tiers'

    export default {
        computed: {
            Tiers() {
                return Tiers
            }
        },
        mixins: [itemChange],
        components: {
            ValidationProvider,
            ValidationObserver,
            BOverlay,
            BCard,
            BFormInput,
            BButton,
            BFormGroup,
            BFormCheckbox,
            BFormTextarea,
            vSelect
        },
        data() {
            return {
                signature_types: [
                    {
                        type: 0,
                        label: this.$t('config.payment_deadline.mark')
                    },
                    {
                        type: 1,
                        label: this.$t('config.payment_deadline.signature')
                    },
                    {
                        type: 2,
                        label: this.$t('config.payment_deadline.mark_and_signature')
                    },
                    {
                        type: 3,
                        label: this.$t('config.payment_deadline.without')
                    }
                ],
                own_consumption_types: [
                    {
                        type: 0,
                        label: this.$t('config.payment_deadline.use_sale_price')
                    },
                    {
                        type: 1,
                        label: this.$t('config.payment_deadline.use_buy_price')
                    },
                    {
                        type: 2,
                        label: this.$t('config.payment_deadline.use_buy_price_and_percentage')
                    }
                ],
                warehouse_stock_types: [
                    {
                        type: 1,
                        label: this.$t('config.payment_deadline.for_all_warehouses')
                    },
                    {
                        type: 2,
                        label: this.$t('config.payment_deadline.for_each_warehouse')
                    }
                ],
                showLoader: false,
                paymentTypes: {},
                paymentTypeCash: null,
                paymentTypeCard: null,
                invoiceConfig: {},
                cashRegisterConfig: {},
                showInvoiceConfig: {},
                required,
                numeric,
                minValue,
                alpha,
                vSelect,
                own_consumption: {},
                warehouse_stock: {},
                productSubcategories: []
            }
        },
        methods: {
            async loadData() {
                try {
                    this.showLoader = true

                    const response1 = await this.$http.get('/api/client/v1/invoice_config')
                    const response2 = await this.$http.get('/api/client/v1/invoice_config/own_consumption')
                    const response3 = await this.$http.get('/api/client/v1/payment_types/normal')
                    const response4 = await this.$http.get('/api/client/v1/invoice_config/warehouse_stock')
                    const response5 = await this.$http.get('/api/client/v1/invoice_config/payment_type')
                    const response6 = await this.$http.get('/api/client/v1/invoice_config/cash_register')
                    const response7 = await this.$http.get('/api/client/v1/product_subcategories/')

                    const responses = await Promise.all([
                        response1,
                        response2,
                        response3,
                        response4,
                        response5,
                        response6,
                        response7
                    ])

                    this.invoiceConfig = responses[0].data ?? {}
                    this.own_consumption = responses[1].data ?? {}
                    this.paymentTypes = responses[2].data ?? {}
                    this.own_consumption.percentage = Number(
                        parseInt(this.own_consumption.percentage) / 100
                    )
                        .toFixed(2)
                        .replace('.00', '')
                    this.warehouse_stock = responses[3].data ?? {}
                    this.showInvoiceConfig = this.invoiceConfig
                    this.paymentTypeCard = responses[4].data.card_payment_id ?? null
                    this.paymentTypeCash = responses[4].data.cash_payment_id ?? null
                    this.cashRegisterConfig = responses[5].data ?? null
                    this.productSubcategories = responses[6].data ?? []

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
                await this.itemChange()
            },
            validateCashRegisterConfiguration() {
                this.$refs.validationCashRegister.validate().then((success) => {
                    if (success) {
                        this.changeCashRegisterConfiguration()
                    }
                })
            },
            validatePaymentType() {
                this.$refs.validationPaymentType.validate().then((success) => {
                    if (success) {
                        this.changePaymentType()
                    }
                })
            },
            validate() {
                this.$refs.validation.validate().then((success) => {
                    if (success) {
                        this.changeInvoiceConfig()
                    }
                })
            },
            validateOwnConsumption() {
                this.$refs.validationOwnConsumption.validate().then((success) => {
                    if (success) {
                        this.changeOwnConsumptionConfig()
                    }
                })
            },
            validateWarehouseStock() {
                this.$refs.validationWarehouseStock.validate().then((success) => {
                    if (success) {
                        this.changeWarehouseStockConfig()
                    }
                })
            },
            async changeWarehouseStockConfig() {
                try {
                    this.showLoader = true

                    const payload = {
                        stock_type: this.warehouse_stock.stock_type
                    }

                    await this.$http.post(
                        '/api/client/v1/invoice_config/warehouse_stock',
                        payload
                    )

                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async changeOwnConsumptionConfig() {
                try {
                    this.showLoader = true

                    if (this.own_consumption.own_consumption_type !== 2) {
                        this.own_consumption.percentage = 0
                    }

                    const payload = {
                        own_consumption_type: this.own_consumption.own_consumption_type,
                        percentage: parseInt(this.own_consumption.percentage) * 100
                    }

                    await this.$http.post(
                        '/api/client/v1/invoice_config/own_consumption',
                        payload
                    )

                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async changeInvoiceConfig() {
                try {
                    this.showLoader = true
                    this.showInvoiceConfig.postcode = parseInt(this.showInvoiceConfig.postcode)
                    this.showInvoiceConfig.number_of_days = parseInt(this.showInvoiceConfig.number_of_days)
                    this.showInvoiceConfig.header_offset = parseInt(this.showInvoiceConfig.header_offset)
                    this.showInvoiceConfig.footer_offset = parseInt(this.showInvoiceConfig.footer_offset)
                    this.showInvoiceConfig.signature_type = parseInt(this.showInvoiceConfig.signature_type)
                    this.showInvoiceConfig.size_of_text = parseInt(this.showInvoiceConfig.size_of_text)


                    await this.$http.post(
                        '/api/client/v1/invoice_config',
                        this.showInvoiceConfig
                    )

                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async changeCashRegisterConfiguration() {
                try {
                    this.showLoader = true


                    await this.$http.post(
                        '/api/client/v1/invoice_config/cash_register',
                        this.cashRegisterConfig
                    )

                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async changePaymentType() {
                try {
                    this.showLoader = true
                    const payload = {
                        cash_payment_id: this.paymentTypeCash,
                        card_payment_id: this.paymentTypeCard
                    }

                    await this.$http.post(
                        '/api/client/v1/invoice_config/payment_type',
                        payload
                    )

                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            }
        },
        async mounted() {
            await this.loadData()
        }
    }
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
